import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { apiBaseUrl } from "../../config";
import Footer from "../shared/Footer/Footer";
import Header from "../shared/Header/Header";
import "./CreatePayment.css";

const CreatePayment = () => {
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState("");
    const [currency, setCurrency] = useState("cad");
    const [link, setLink] = useState(null);
    const [spinLoading, setSpinLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSpinLoading(true);
        axios
            .post(`${apiBaseUrl}/app/stripe`, {
                currency,
                amount: parseFloat(amount),
            })
            .then((res) =>
                setLink(`${window.location.origin}/payment/${res.data}`)
            )
            .catch((err) => console.log(err))
            .finally(() => setSpinLoading(false));
    };

    function copyToClipboard() {
        const elementId = document.getElementById("link");
        var aux = document.createElement("input");
        aux.setAttribute("value", elementId.innerHTML);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);

        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copied";
    }

    function outFunc() {
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    }

    return (
        <>
            {loading ? (
                <div className="loader">
                    <div
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        className="loader-title"
                    >
                        <h1>
                            CREATE<span className="text-dark">PAYMENT</span>
                        </h1>
                        <Spinner
                            className="p-2 mx-2"
                            animation="grow"
                            size="sm"
                        ></Spinner>
                        <Spinner
                            className="p-2"
                            animation="grow"
                            size="sm"
                        ></Spinner>
                    </div>
                </div>
            ) : (
                <div id="payment">
                    <Header></Header>
                    <Container>
                        <div
                            data-aos="fade-up"
                            data-aos-duration="1300"
                            className="pt-5"
                        >
                            <h1 className="about-intro text-start pt-5">
                                Enter amount to be charged
                            </h1>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-duration="1500"
                            className="py-5 mb-5"
                        >
                            <div className="create-payment-form my-5 shadow">
                                <div className="pt-5">
                                    <form
                                        onSubmit={handleSubmit}
                                        className="py-5"
                                    >
                                        <select
                                            value={currency}
                                            onChange={(e) =>
                                                setCurrency(e.target.value)
                                            }
                                            className="input"
                                            placeholder="Your name"
                                            type="text"
                                            required
                                        >
                                            <option
                                                className="text-dark"
                                                value="cad"
                                            >
                                                Canadian Dollar (CAD)
                                            </option>
                                            <option
                                                className="text-dark"
                                                value="inr"
                                            >
                                                Indian Rupee (INR)
                                            </option>
                                            <option
                                                className="text-dark"
                                                value="usd"
                                            >
                                                US Dollar (USD)
                                            </option>
                                            <option
                                                className="text-dark"
                                                value="aud"
                                            >
                                                Australian Dollar (USD)
                                            </option>
                                        </select>
                                        <input
                                            value={amount}
                                            onChange={(e) =>
                                                setAmount(e.target.value)
                                            }
                                            className="input"
                                            placeholder="Enter Amount"
                                            type="number"
                                            required
                                        />
                                        <button
                                            type="submit"
                                            disabled={spinLoading}
                                            className="fw-bold btn btn-outline-dark w-75 text-white fs-1 my-5"
                                        >
                                            {spinLoading ? (
                                                <div className="spinLoading ms-3"></div>
                                            ) : (
                                                <>
                                                    Generate Link
                                                    <i className="arrow ms-3 fas fa-long-arrow-alt-right"></i>
                                                </>
                                            )}
                                        </button>
                                        {link && (
                                            <button
                                                type="button"
                                                onClick={copyToClipboard}
                                                onMouseOut={outFunc}
                                                className="tooltiptip w-100 text-white mb-5 bg-secondary link p-3"
                                            >
                                                <span
                                                    className="tooltiptext"
                                                    id="myTooltip"
                                                >
                                                    Copy to clipboard
                                                </span>
                                                <div id="link">{link}</div>
                                            </button>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Footer></Footer>
                </div>
            )}
        </>
    );
};

export default CreatePayment;
