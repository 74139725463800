import React from 'react';
import "./Banner.css"
import { Col, Container, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import websiteVideo from "../../../videos/websites.mp4"
import appVideo from "../../../videos/apps.mp4"
import brnadingVideo from "../../../videos/branding.mp4"
import formobileVideo from "../../../videos/Untitled design.mp4"


const Banner = () => {
    return (
        <>
            <div className='d-false'>
                <Container>
                    <Row className='pt-5'>
                        <Col className='py-5' xs={12} md={6}>
                            <div className='w-90'>
                                <div id="video-container">
                                    <video
                                        style={{ display: 'block' }}
                                        className='w-100'
                                        loop
                                        autoPlay
                                        muted
                                    >
                                        <source src={formobileVideo} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div data-aos="fade-up" data-aos-duration="1100" style={{ display: 'block' }} className='py-5 px-3'>
                                <div className='text-start'>
                                    <h3 id="punch-line-mobile">We make it happen</h3>
                                    <HashLink style={{ textDecoration: 'none' }} smooth to="/services#website">
                                        <h1><span className='hero-text-mobile'>Website</span></h1>
                                    </HashLink>
                                </div>
                                <div className='text-start' >
                                    <h3 className='hidden'>#</h3>
                                    <HashLink style={{ textDecoration: 'none' }} smooth to="/services#app">
                                        <h1><span className='hero-text-middle-mobile'>Apps</span></h1>
                                    </HashLink>
                                </div>
                                <div className='text-start' >
                                    <h3 className='hidden'>#</h3>
                                    <HashLink style={{ textDecoration: 'none' }} smooth to="/services#ui_ux">
                                        <h1><span className='hero-text-last-mobile'>UI/UX</span></h1>
                                    </HashLink>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <div style={{ display: 'block' }} className='pt-5'>
                        <div className='text-start py-5 w-75'>
                            <p className='brand-text pb-5'>Highly proficient digital agency with well founded design, evolution and expansion expertise. We build convenient professional websites, mobile applications, and elaborate online business services.</p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='d-true'>
                <div className='banner'>
                    <Container>
                        <div className='py-5 mt-5'>
                            <Row className='my-5 pt-5'>
                                <Col xs={12} md={4}>
                                    <div className='frame-container'>
                                        <div className='video'>
                                            <video
                                                loop
                                                autoPlay
                                                muted
                                            >
                                                <source src={websiteVideo} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className='hero-text'>
                                            <div data-aos="fade-up" data-aos-duration="800" className='text-start'>
                                                <h3 id="punch-line">We make it happen</h3>
                                                <HashLink style={{ textDecoration: 'none' }} smooth to="/services#website">
                                                    <h1>Websites</h1>
                                                </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} xs={12}>
                                    <div className='frame-container'>
                                        <div className='video'>
                                            <video
                                                loop
                                                autoPlay
                                                muted
                                            >
                                                <source src={appVideo} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className='hero-text-middle'>
                                            <div data-aos="fade-up" data-aos-duration="1300" >
                                                <h3 className='hidden'>#</h3>
                                                <HashLink style={{ textDecoration: 'none' }} smooth to="/services#app">
                                                    <h1 className='text-center'>Apps</h1>
                                                </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} xs={12}>
                                    <div className='frame-container'>
                                        <div className='video'>
                                            <video
                                                loop
                                                autoPlay
                                                muted
                                            >
                                                <source src={brnadingVideo} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className='hero-text-last'>
                                            <div data-aos="fade-up" data-aos-duration="1600" >
                                                <h3 className='hidden'>#</h3>
                                                <HashLink style={{ textDecoration: 'none' }} smooth to="/services#ui_ux">
                                                    <h1>UI/UX</h1>
                                                </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Container>
                    <div className='pt-3'>
                        <div data-aos="fade-up" data-aos-duration="1900" className='text-start py-5 w-75'>
                            <p className='brand-text pb-4'>Highly proficient digital agency with well founded design, evolution and expansion expertise. We build convenient professional websites, mobile applications, and elaborate online business services.</p>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Banner;