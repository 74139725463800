import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/free-mode"
import "swiper/css/pagination"
import './FeatureProjects.css'
import SwiperCore, {
    FreeMode,
    Pagination,
    Navigation,
    Autoplay
} from 'swiper';
import { Col, Container, Row } from 'react-bootstrap';
// images for feature section
import one from "../../../Images/featureProjects/781c51e4882db215f4e7d2dcb67f607e(R).png"
import two from "../../../Images/featureProjects/15ff768de87818e82283950d775a31c7(R).png"
import three from "../../../Images/featureProjects/16daa34ccde6dd68d699264d205c6487.png"
import four from "../../../Images/featureProjects/783a92276e283a795d0242875ad40bd2(R).png"
import five from "../../../Images/featureProjects/8ed703b2aefd56860574e2aba7bdacec(R).png"
import six from "../../../Images/featureProjects/7f3bb1810128499bc6df22a443e2abd9(R).png"

SwiperCore.use([FreeMode, Pagination, Navigation, Autoplay]);

const FeatureProjects = () => {
    const featureImg = [
        {
            id: 1,
            img: one,
            title: 'Orderly',
            des: `The most exquisite and authentic delicacies top rated restaurants around the corner.`,
            tags: 'design, mobile, product'
        },
        {
            id: 2,
            img: two,
            title: 'Punch me',
            des: 'An app with the best loyalty programme where punching everytime you visit and gets rewarded with gratifying products.',
            tags: 'design, mobile, product'
        },
        {
            id: 3,
            img: three,
            title: 'HR recruitment',
            des: `The search is over. Find most prime, competent and qualified jobs with us. We help you meet the job that's made just for you.`,
            tags: 'design, mobile, product'
        },
        {
            id: 4,
            img: four,
            title: 'Damus Travel',
            des: `We help you to connect with the world through the most tailored itineraries including Luxury accommodation.`,
            tags: 'design, mobile, product'
        },
        {
            id: 5,
            img: five,
            title: 'Doctor services app',
            des: `Get the trained, specialised and exceptional orthopedics, physiotherapists etc`,
            tags: 'design, mobile, product'
        },
        {
            id: 6,
            img: six,
            title: 'Dliver',
            des: `Now all your cravings are relished with tastiest, yummylicious binging food delivered earliest at your doorstep with  100% safety and hygiene standards.`,
            tags: 'design, mobile, product'
        }
    ]
    return (
        <Container className='py-4'>
            <div className='text-start py-3'>
                <h1 className='n-stroke'>featured</h1>
                <h1 className='stroke'>projects</h1>
            </div>
            <div className='show-false'>
                <Row className='g-3'>
                    {
                        featureImg.map(p => <Col
                            key={p.id}
                            xs={12}
                            sm={12}
                            md={6}
                            className='py-5'
                        >
                            <div>
                                <img className='w-100 img-fluid rounded-3' src={p.img} alt="" />
                            </div>
                            <div className='p-5 text-start'>
                                <h1 className='work-title'>{p.title}<span className='work-title-sub'> — {p.des}</span></h1>
                            </div>
                        </Col>)
                    }
                </Row>
            </div>
            <div className='show-true'>
                <div className='showcase-container py-4'>
                    <Swiper
                        centeredSlides={true}
                        slidesPerView={2}
                        spaceBetween={200}
                        freeMode={true}
                        navigation={true}
                        // loop={true}
                        pagination={{
                            "clickable": true
                        }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false
                        }}

                        className="mySwiper py-5">
                        {
                            featureImg.map(p => <SwiperSlide
                                key={p.id}
                            >
                                <div className='product-container'>
                                    <div className='product-intro'>
                                        <div className='p-5 text-start text-white'>
                                            <h3 className='pt-4'>{p.title}</h3>
                                            <p className='pt-3'>{p.des}</p>
                                        </div>
                                    </div>
                                    <div className='product shadow'>
                                        <img className='img-fluid w-100' src={p.img} alt="" />
                                    </div>
                                </div>
                            </SwiperSlide>)
                        }
                    </Swiper>
                </div >
            </div>
        </Container >
    );
};

export default FeatureProjects;