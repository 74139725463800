/* eslint-disable react-hooks/exhaustive-deps */
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../shared/Footer/Footer";
import Header from "../shared/Header/Header";
import "./Payment.css";

const Payment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [AmountCharged, setAmountCharged] = useState(0);
    const [currency, setCurrency] = useState("");
    const [clientSecret, setClientSecret] = useState(null);
    const [status, setStatus] = useState("");
    const [spinLoading, setSpinLoading] = useState(false);
    const params = useParams();

    useEffect(() => {
        if (params?.id && stripe) {
            setClientSecret(params.id);
            stripe.retrievePaymentIntent(params.id).then((res) => {
                if (res.paymentIntent.status === "succeeded")
                    setStatus("alreadyPaid");
                setAmountCharged(res.paymentIntent.amount);
                setCurrency(res.paymentIntent.currency);
            });
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        document
            .getElementById("payment")
            ?.scrollIntoView({ behavior: "smooth" });
    }, [status]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSpinLoading(true);
        stripe
            .confirmCardPayment(clientSecret, {
                payment_method: {
                    type: "card",
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name,
                        email,
                        phone,
                        address: {
                            line1: "1 Main street",
                            city: "San Francisco",
                            postal_code: "90210",
                            state: "CA",
                            country: "US",
                        },
                    },
                },
                receipt_email: email,
            })
            .then((data) => {
                if (data?.paymentIntent?.status)
                    setStatus(data.paymentIntent.status);
                else if (data.error) setStatus("failed");
            })
            .finally(() => setSpinLoading(false));
    };
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontWeight: 500,
                color: "white",
                fontSize: "30px",
                "::placeholder": { fontWeight: 500 },
            },
            invalid: { color: "tomato" },
        },
    };

    return (
        <>
            {loading ? (
                <div className="loader">
                    <div
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        className="loader-title"
                    >
                        <h1>
                            PAY<span className="text-dark">MENT</span>
                        </h1>
                        <Spinner
                            className="p-2 mx-2"
                            animation="grow"
                            size="sm"
                        ></Spinner>
                        <Spinner
                            className="p-2"
                            animation="grow"
                            size="sm"
                        ></Spinner>
                    </div>
                </div>
            ) : (
                <div id="payment">
                    <Header></Header>
                    <Container>
                        {status === "alreadyPaid" || status === "succeeded" ? (
                            <div
                                data-aos="fade-up"
                                data-aos-duration="1500"
                                className="my-5 pt-5"
                            >
                                <div className="payment-form my-5 py-5 shadow">
                                    <div className="py-5 d-flex justify-content-center">
                                        <div className="fw-bold w-75 text-white fs-1 my-5">
                                            {`Payment ${
                                                status === "alreadyPaid"
                                                    ? "already completed"
                                                    : "Completed"
                                            }`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : status === "failed" ? (
                            <div
                                data-aos="fade-up"
                                data-aos-duration="1500"
                                className="my-5 pt-5"
                            >
                                <div className="payment-form my-5 py-5 shadow">
                                    <div className="py-5 d-flex justify-content-center">
                                        <div className="fw-bold w-75 text-white fs-1 my-5">
                                            {`Payment Failed`}
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="fw-bold btn btn-outline-dark w-25 bg-white text-dark fs-1 mb-5"
                                        onClick={() => setStatus("")}
                                    >
                                        Retry
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1300"
                                    className="py-5"
                                >
                                    <h1 className="about-intro text-start pt-5">
                                        Amount to be charged:
                                        <span className="font-weight-bold">{` ${(
                                            AmountCharged / 100
                                        ).toFixed(
                                            2
                                        )} (${currency.toUpperCase()})`}</span>
                                    </h1>
                                </div>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1300"
                                    className="py-5"
                                >
                                    <h1 className="about-intro text-start">
                                        {"Enter payment details"}
                                    </h1>
                                </div>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    className="my-5"
                                >
                                    <div className="payment-form my-5 shadow">
                                        <div className="py-5">
                                            <form
                                                onSubmit={handleSubmit}
                                                className="py-5"
                                            >
                                                <input
                                                    value={name}
                                                    onChange={(e) =>
                                                        setName(e.target.value)
                                                    }
                                                    className="input"
                                                    placeholder="Your name"
                                                    type="text"
                                                    required
                                                />
                                                <input
                                                    value={email}
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                    className="input"
                                                    placeholder="Your email"
                                                    type="email"
                                                    required
                                                />
                                                <input
                                                    value={phone}
                                                    onChange={(e) =>
                                                        setPhone(e.target.value)
                                                    }
                                                    name="phone"
                                                    className="input"
                                                    placeholder="Your phone number"
                                                    type="tel"
                                                    required
                                                />
                                                <CardNumberElement
                                                    options={{
                                                        placeholder:
                                                            "card number",
                                                        ...CARD_ELEMENT_OPTIONS,
                                                    }}
                                                    className="input"
                                                    required
                                                />
                                                <div className="w-100 d-flex">
                                                    <CardExpiryElement
                                                        options={{
                                                            placeholder:
                                                                "valid vhrough (mm/yy)",
                                                            ...CARD_ELEMENT_OPTIONS,
                                                        }}
                                                        className="input"
                                                    />
                                                    <CardCvcElement
                                                        options={{
                                                            placeholder: "CVV",
                                                            ...CARD_ELEMENT_OPTIONS,
                                                        }}
                                                        className="input"
                                                    />
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="fw-bold btn btn-outline-dark w-75 text-white fs-1 my-5 align-middle"
                                                >
                                                    {spinLoading ? (
                                                        <div className="spinLoading ms-3"></div>
                                                    ) : (
                                                        <>
                                                            Complete Payment
                                                            <i className="arrow ms-3 fas fa-long-arrow-alt-right"></i>
                                                        </>
                                                    )}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Container>
                    <Footer></Footer>
                </div>
            )}
        </>
    );
};

export default Payment;
