import React from 'react';
import Footer from '../../shared/Footer/Footer';
import Header from '../../shared/Header/Header';
import Banner from '../Banner/Banner';
import FeatureProjects from '../FeatureProjects/FeatureProjects';
import SocialReels from '../SocialReels/SocialReels';

const Home = () => {
    return (
        <div id="home">
            <Header></Header>
            <Banner></Banner>
            <FeatureProjects></FeatureProjects>
            <SocialReels></SocialReels>
            <Footer></Footer>
        </div>
    );
};

export default Home;