import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/free-mode"
import "swiper/css/pagination"
import SwiperCore, {
    FreeMode,
    Pagination,
    Navigation,
    Autoplay
} from 'swiper';
import "./SocialReels.css"
import { Container } from 'react-bootstrap';
// images for showreel
import img1 from "../../../Images/socialReels/filefpundry1_dr_1x(R).png"
import img3 from "../../../Images/socialReels/finance_dar_kui_design_1x(R).png"
import img4 from "../../../Images/socialReels/frame_1171273135_1x(R).png"
import img5 from "../../../Images/socialReels/joon_1_dr_1x(R).png"
import img6 from "../../../Images/socialReels/pleaseass_dr_1_1x(R).png"
import img7 from "../../../Images/socialReels/punto_showreel_dr_1x(R).png"
import img8 from "../../../Images/socialReels/robin_dr_1x(R).png"
import img9 from "../../../Images/socialReels/vacuum_cleaner_app_dr_1x(R).png"

SwiperCore.use([FreeMode, Pagination, Navigation, Autoplay]);

const SocialReels = () => {
    const reels = [
        {
            id: 1,
            img: img1,
            title: 'techguardians',
            des: 'WWB global'
        },
        {
            id: 3,
            img: img3,
            title: 'techguardians',
            des: 'Easy store'
        },
        {
            id: 4,
            img: img4,
            title: 'techguardians',
            des: 'Perfect Mate'
        },
        {
            id: 5,
            img: img5,
            title: 'techguardians',
            des: 'Dilvr'
        },
        {
            id: 6,
            img: img6,
            title: 'techguardians',
            des: 'Easy physio'
        },
        {
            id: 7,
            img: img7,
            title: 'techguardians',
            des: 'Ui/Ux'
        },
        {
            id: 8,
            img: img8,
            title: 'techguardians',
            des: 'Jab we mate'
        },
        {
            id: 9,
            img: img9,
            title: 'techguardians',
            des: 'Bbdg'
        }
    ]
    return (
        <div className='py-4'>
            <Container className='py-3'>
                <div className='text-start py-3'>
                    <h1 className='b-stroke'>New day —</h1>
                    <h1 className='b-stroke'>new inspiro</h1>
                </div>
                <div className='social-reels py-3 n-mobile'>
                    <Swiper
                        slidesPerView={3}
                        // centeredSlides={true}
                        spaceBetween={50}
                        freeMode={true}
                        // pagination={{
                        //     clickable: true
                        // }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        loop={true}
                        navigation={true}
                        className="mySwiper pb-5"
                    >
                        {
                            reels.map(reel => <SwiperSlide
                                key={reel.id}
                            >
                                <div>
                                    <div className='my-5 shadow'>
                                        <img className='img-fluid w-100' src={reel.img} alt="" />
                                    </div>
                                    <div className='text-start'>
                                        <p className='des-text'>{reel.des}</p>
                                    </div>
                                </div>
                            </SwiperSlide>)
                        }
                    </Swiper>
                </div>
                <div className='social-reels py-5 mobile'>
                    <Swiper
                        slidesPerView={2}
                        // centeredSlides={true}
                        spaceBetween={30}
                        freeMode={true}
                        // pagination={{
                        //     clickable: true
                        // }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        loop={true}
                        navigation={true}
                        className="mySwiper pb-5"
                    >
                        {
                            reels.map(reel => <SwiperSlide
                                key={reel.id}
                            >
                                <div>
                                    <div className='my-5 shadow'>
                                        <img className='img-fluid w-100' src={reel.img} alt="" />
                                    </div>
                                    <div className='text-start'>
                                        <h5 className='fw-bold'><i className="fab fa-dribbble me-2"></i>{reel.title}</h5>
                                        <p className='des-text'>{reel.des}</p>
                                    </div>
                                </div>
                            </SwiperSlide>)
                        }
                    </Swiper>
                </div>
            </Container>
        </div>
    );
};

export default SocialReels;