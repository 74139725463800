import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import SocialReels from '../Home/SocialReels/SocialReels';
import Footer from '../shared/Footer/Footer';
import Header from '../shared/Header/Header';
import "./About.css"

const About = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <>
            {
                loading ?
                    <div className="loader">
                        <div data-aos="fade-in" data-aos-duration="1000" className="loader-title">
                            <h1>AB<span className='text-dark'>OUT</span></h1>
                            <Spinner className='p-2 mx-2' animation="grow" size='sm' ></Spinner>
                            <Spinner className='p-2' animation="grow" size='sm' ></Spinner>
                        </div >
                    </div >
                    :
                    <div>
                        <Header></Header>
                        <section>
                            <Container className='py-4'>
                                <div data-aos="fade-up" data-aos-duration="1300" className='py-4'>
                                    <h1 className='about-intro text-start py-5'>
                                        Creativity <br />
                                        meets technology
                                    </h1>
                                </div>
                            </Container>
                            <div data-aos="fade-up" data-aos-duration="1500" className='about-banner'>
                                <img className='img-fluid w-100' src="https://cdn.cuberto.com/cb/img/about/1.jpg" alt="" />
                            </div>
                            <Container className='py-5'>
                                <div>
                                    <Row className='py-5'>
                                        <Col xs={12} md={4} className='text-start'>
                                            <h4 className='p-3'>Our goal</h4>
                                        </Col>
                                        <Col xs={12} md={8} className='text-start'>
                                            <p className='about-des p-3'>
                                                From the time our company was founded, we ensure helping our clients to find exceptional solutions for their businesses, creating highly memorable brands and digital products. Our expertise grows with each year, and our accumulated experience entitle us to develop products exactly as they should be.
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                            <Container className='py-5'>
                                <div>
                                    <Row className='g-1 py-3'>
                                        <Col xs={12} md={6} className='px-4'>
                                            <img className='img-fluid w-100' src="https://cdn.cuberto.com/cb/img/about/2.jpg" alt="" />
                                        </Col>
                                        <Col xs={12} md={6} className='px-4'>
                                            <div className='my-5 py-5'>

                                            </div>
                                            <img className='img-fluid w-100' src="https://cdn.cuberto.com/cb/img/about/3.jpg" alt="" />
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                            <Container className='pt-5'>
                                <div>
                                    <h1 className='about-intro text-start py-3 p-4'>
                                        Simply put, we dare <br />
                                        what others don't
                                    </h1>
                                    <p className='about-des-2 w-75 text-start p-4'>
                                        We build things, and we’re absolutely amazing at it. Techguardians is a tight-knit skillfull  team of experts who are ready to tackle the most intricate puzzles when it comes to websites and mobile apps development. We enjoy what we do and we bet on the success of each and every project we undertake.
                                    </p>
                                    <p className='about-des-2 w-75 text-start p-4'>
                                        Too Mainstream? No, thanks. Because it’s not just work for everyone , it’s passion. It’s not just clients, it’s people we adore. Every project we take on is extremely important to us, and every client is a big deal. We take care of your projects, your deadlines, and your nerves no matter what, and that’s a promise.
                                    </p>
                                </div>
                                <div className='py-5 mb-5'>
                                    <div className='py-5'>
                                        <img className='w-100 img-fluid' src="https://cdn.cuberto.com/cb/img/about/4.jpg" alt="" />
                                    </div>
                                </div>
                            </Container>

                            <Container className='py-5'>
                                <div>
                                    <Row className='g-1 py-3'>
                                        <Col xs={12} md={6} className='px-4'>
                                            <img className='img-fluid w-100' src="https://cdn.cuberto.com/cb/img/about/5.jpg" alt="" />
                                        </Col>
                                        <Col xs={12} md={6} className='px-4'>
                                            <div className='my-5 py-5'>

                                            </div>
                                            <img className='img-fluid w-100' src="https://cdn.cuberto.com/cb/img/about/6.jpg" alt="" />
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                            <Container className='py-5'>
                                <div>
                                    <h1 className='about-intro text-start py-3 p-4'>
                                        Get to know us, <br />
                                        see what's up
                                    </h1>
                                    <p className='about-des-2 w-75 text-start p-4'>
                                        Tech Guardians has grown to a full-cycle agency with highly attested design expertise. We are highly efficient, works co-opeatively and are popularly known in the field of design communities across the world, received recognition, worked with multinational mega corporates, provides an opportunity to share our skills, expertise and experience.
                                    </p>
                                </div>
                            </Container>
                            <SocialReels></SocialReels>
                            <Footer></Footer>
                        </section>
                    </div>
            }
        </>
    );
};

export default About;