import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import "./Footer.css"

const Footer = () => {
    return (
        <section className='footer'>
            <Container>
                <div className='pt-5'>
                    <div className='text-start px-3 py-5'>
                        <h1 className='idea'>Have an idea?</h1>
                        <HashLink className='link' smooth to="/contact#contact">
                            <h1><span className='link-hover' >Tell us about it<i className="fas fa-arrow-right ms-4"></i></span></h1>
                        </HashLink>
                    </div>
                    <div className='px-3'>
                        <div className='text-start'>
                            <h5><span className='mail-footer'>info@thetechguardians.com</span></h5>
                            <h5><span className='mail-footer'>+1 (888) 851 8988 (Toll Free)</span></h5>
                            <address className='py-1'>
                                <p>
                                    55 village centre place, Mississauga, <br />
                                    Ontario L4Z 1V9, Canada
                                </p>
                            </address>
                        </div>
                        <Row className='g-5 py-5' >
                            <Col xs={12} md={6}>
                                <div className='text-start'>
                                    <p className='text-white mail'>
                                        Privacy Policy
                                    </p>
                                    <p className='text-white'>
                                        ©2022 - TheTechguardians.com - All Rights Reserved.
                                    </p>
                                </div>
                            </Col>
                            <Col className='display-true' xs={12} md={6}>
                                <div className='social'>
                                    <div>
                                        <Row className='g-3'>
                                            <Col className='text-white mail text-start' md={3}><a href="https://www.facebook.com/Tech-Guardians-109376041708090" target="_blank" rel="noreferrer">Facebook</a></Col>
                                            <Col className='text-white mail text-start' md={3}><a href="https://www.instagram.com/tech.guardians/" target="_blank" rel="noreferrer">Instragram</a></Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Footer;