import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import Footer from '../shared/Footer/Footer';
import Header from '../shared/Header/Header';
import "./Contact.css"

const Contact = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <>
            {
                loading ?
                    <div className="loader">
                        <div data-aos="fade-in" data-aos-duration="1000" className="loader-title">
                            <h1>CONT<span className='text-dark'>ACT</span></h1>
                            <Spinner className='p-2 mx-2' animation="grow" size='sm' ></Spinner>
                            <Spinner className='p-2' animation="grow" size='sm' ></Spinner>
                        </div >
                    </div >
                    :
                    <div id="contact">
                        <Header></Header>
                        <Container>
                            <div data-aos="fade-up" data-aos-duration="1300" className='py-5'>
                                <h1 className='about-intro text-start py-5'>
                                    Hey! Tell us all<br />
                                    the things ✌
                                </h1>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1500" className='py-5 mb-5'>
                                <div className="contact-form my-5 shadow">
                                    <div className="pt-5">
                                        <form className="py-5" >
                                            <input name="interest" placeholder="I'm interest in..." type="text" required />
                                            <input name="name" placeholder="Your name" type="text" required />
                                            <input name="user_email" placeholder="Your email" type="email" required />
                                            <textarea name="message" rows="4" placeholder="Tell us about your project" type="text" required />
                                            <input name="user_budget" placeholder="Project budget (USD)" type="email" required />
                                            <button type="submit" className="fw-bold btn btn-outline-dark w-75 text-white fs-1 my-5">Send<i className="arrow ms-3 fas fa-long-arrow-alt-right"></i></button>                                </form>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <Footer></Footer>
                    </div>
            }
        </>
    );
};

export default Contact;