import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import SocialReels from '../Home/SocialReels/SocialReels';
import Footer from '../shared/Footer/Footer';
import Header from '../shared/Header/Header';
import "./Work.css"
// images for work 
import img1 from '../../Images/Web/card (1)(R).jpg'
import img2 from '../../Images/Web/card (2)(R).jpg'
import img3 from '../../Images/Web/card (3)(R).jpg'
import img4 from '../../Images/Web/card (4)(R).jpg'
import img5 from '../../Images/Web/card (5)(R).jpg'
import img6 from '../../Images/Web/card (6)(R).jpg'
import img7 from '../../Images/Web/card (7)(R).jpg'
import img8 from '../../Images/Web/card (8)(R).jpg'
import img9 from '../../Images/Web/card (9)(R).jpg'
import img10 from '../../Images/Web/card (10)(R).jpg'
import img11 from '../../Images/Web/card(R).jpg'

const Work = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    const works = [
        {
            id: 1,
            title: 'Dliver',
            title_sub: `Now all your cravings are relished with tastiest, yummylicious binging food delivered earliest at your doorstep with  100% safety and hygiene standards.`,
            img: img1
        },
        {
            id: 2,
            title: 'Doctor services app',
            title_sub: `Get the trained, specialised and  exceptional orthopedics, physiotherapists etc and be free of all your worries. Have the best experience of all times with our trusted
            Technoscientifically specialists.`,
            img: img2
        },
        {
            id: 3,
            title: 'Bbdg website',
            title_sub: `Our highly qualified experts have industry and sector experience which focuses on a structured, business-driven approach that integrates proven methodologies, techniques and best practices that deliver practical, substantial and corporeal results. We believe working with us can help you apply effective 
            Helping you build a prosperous, efficient business.`,
            img: img3
        },
        {
            id: 4,
            title: 'PTE website',
            title_sub: 'Have a Dream to study abroad? Turn your dream into reality with this amazing opportunity to learn the best from the best! With thorough preparation tips and practice tests you can polish skills and top leading your life.',
            img: img4
        },
        {
            id: 5,
            title: 'Punch me',
            title_sub: `An app that treats their customers rightfully integral with the best loyalty programme where  punching  everytime you visit and gets rewarded with gratifying products.`,
            img: img5
        },
        {
            id: 6,
            title: 'Ui/UX designs',
            title_sub: `User interface design or user interface engineering is the design of user interfaces for machines, software and electronics devices with the focus on maximizing usability and the user experience.`,
            img: img6
        },
        {
            id: 7,
            title: 'Orderly',
            title_sub: `Have the most exquisite and authentic delicacies at the  top rated restaurants around the corner and relish your mouthwatering delicateness.`,
            img: img7
        },
        {
            id: 8,
            title: 'Salon',
            title_sub: `Have a  dapper day with our skillfull specialists that groom and shipshape  into a new and better you.`,
            img: img8
        },
        {
            id: 9,
            title: 'Hawk systems',
            title_sub: `Take a session with our impeccable team of Hairdressers, Makeup Artists and Spa specialists and savor the well-groomed you.`,
            img: img9
        },
        {
            id: 10,
            title: 'Bbdg website',
            title_sub: `Our highly qualified experts have industry and sector experience which focuses on a structured, business-driven approach that integrates proven methodologies, techniques and best practices that deliver practical, substantial and corporeal results. We believe working with us can help you apply effective 
            Helping you build a prosperous, efficient business.`,
            img: img10
        },
        {
            id: 11,
            title: 'Damus travel',
            title_sub: `An amazing tourism platform where all your dream adventures are accomplished with our lavish, luxurious and grandeur services. Chuck off your travel bucket list today with your loved ones with Damus Travels.`,
            img: img11
        },
    ]
    return (
        <>
            {
                loading ?
                    <div className="loader">
                        <div data-aos="fade-in" data-aos-duration="1000" className="loader-title">
                            <h1>WO<span className='text-dark'>RK</span></h1>
                            <Spinner className='p-2 mx-2' animation="grow" size='sm' ></Spinner>
                            <Spinner className='p-2' animation="grow" size='sm' ></Spinner>
                        </div >
                    </div >
                    :
                    <div>
                        <Header></Header>
                        <Container className='py-3'>
                            <div data-aos="fade-up" data-aos-duration="1300" className='py-5'>
                                <h1 className='about-intro text-start pt-4'>
                                    Explore —<br />
                                    our works
                                </h1>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="1500" className='pb-3 pt-2'>
                                <Row className='g-3'>
                                    {
                                        works.map(work => <Col
                                            key={work.id}
                                            xs={12} md={6}
                                            className='py-5'
                                        >
                                            <div>
                                                <img className='w-100 img-fluid' src={work.img} alt="" />
                                            </div>
                                            <div className='p-5'>
                                                <h1 className='work-title'>{work.title}<span className='work-title-sub'> — {work.title_sub}</span></h1>
                                            </div>
                                        </Col>)
                                    }
                                </Row>
                            </div>
                        </Container>
                        <SocialReels></SocialReels>
                        <Footer></Footer>
                    </div>
            }
        </>
    );
};

export default Work;