import React, { useState } from 'react';
import './Header.css'
import { Col, Container, Nav, Navbar, Offcanvas, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from "../../../Images/favicon.png"


const Header = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className='my-5 wrapper'>
            <Navbar className='py-1 fixed-top' bg="white" variant="light">
                <Container>
                    <HashLink data-aos="fade-in" data-aos-duration="1100" className='text-decoration-none text-dark text-start w-50 me-auto' to="/#home">
                        <Navbar.Brand className='Ubuntu'>
                            <span className='logo-1'>
                                <img src={logo} alt="" className='logoImage' />
                                <span className='logo'>Tech</span>
                                <span className='logo'>Guardians</span>
                            </span>
                        </Navbar.Brand>
                    </HashLink>
                    <div data-aos="fade-in" data-aos-duration="1100" >
                        <button onClick={handleShow} className="btn btn-text fs-5 ms-auto">
                            menu <span className='ps-1'><i className="fas fa-grip-lines text-dark"></i></span>
                        </button>
                    </div>
                    <Offcanvas className='offcanvas' placement='end' show={show} onHide={handleClose}>
                        <Offcanvas.Header className='p-5 ms-auto' closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='p-5'>
                            <Row>
                                <Col className='display-none' md={4}>
                                    <Nav>
                                        <div>
                                            <h6 style={{ color: 'rgb(138, 128, 128)', fontWeight: '400' }}>Social</h6>
                                            <div data-aos="fade-in" data-aos-duration="1500" className='social-menu mt-3'>
                                                <div className="nav-social-link" to="/contact"><a href="https://www.facebook.com/Tech-Guardians-109376041708090" target="_blank" rel="noreferrer">Facebook</a></div>
                                                <div className="nav-social-link" to="/contact"><a href="https://www.instagram.com/tech.guardians/" target="_blank" rel="noreferrer">Instragram</a></div>
                                            </div>
                                        </div>
                                    </Nav>
                                </Col>
                                <Col md={8}>
                                    <Nav>
                                        <div className='menu'>
                                            <div className='text-start'>
                                                <h6 style={{ color: 'rgb(138, 128, 128)', fontWeight: '400' }}>Menu</h6>
                                                <div data-aos="zoom-in-up" data-aos-duration="900" >
                                                    <Nav.Link as={Link} to="/work"><span className="nav-link">Work</span></Nav.Link>
                                                </div>
                                                <div data-aos="zoom-in-up" data-aos-duration="1100" >
                                                    <Nav.Link as={Link} to="/about"><span className="nav-link">About</span></Nav.Link>
                                                </div>
                                                <div data-aos="zoom-in-up" data-aos-duration="1300" >
                                                    <Nav.Link as={Link} to="/services"><span className="nav-link">Services</span></Nav.Link>
                                                </div>
                                                <div data-aos="zoom-in-up" data-aos-duration="1500" >
                                                    <Nav.Link as={Link} to="/contact"><span className="nav-link">Contacts</span></Nav.Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav>
                                </Col>
                                <div className='mt-5'>
                                    <h6 style={{ color: 'rgb(138, 128, 128)', fontWeight: '400' }}>Get in touch</h6>
                                    <h6><span className='mail'>info@thetechguardians.com</span></h6>
                                    <h6><span className='mail'>+1 (888) 851 8988 (Toll Free)</span></h6>
                                </div>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                    {/* </Nav> */}
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;