import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home/Home';
import About from './pages/About/About';
import Work from './pages/Work/Work';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Payment from './pages/Payment/Payment';
import { Scrollbars } from 'react-custom-scrollbars';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import favicon from "./Images/favicon.png"
import { StripePaymentProvider } from './context/stripePayment'
import CreatePayment from './pages/CreatePayment/CreatePayment';
AOS.init();

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1700)
  }, [])

  return (
    <StripePaymentProvider>
      {
        loading ?
          <div className="loader">
            <div data-aos="zoom-in-up" data-aos-duration="1000" className="loader2-title Ubuntu text-start">
              <img className='w-25 img-fluid' src={favicon} alt="" />
              <span>Tech</span>
              <span>Guardians</span>
            </div >
          </div >
          :
          <Scrollbars style={{ width: "100vw", height: "100vh" }}>
            <div className="App">
              <Router>
                <Switch>
                  <Route exact path="/">
                    <Home></Home>
                  </Route>
                  <Route path="/home">
                    <Home></Home>
                  </Route>
                  <Route path="/work">
                    <Work></Work>
                  </Route>
                  <Route path="/about">
                    <About></About>
                  </Route>
                  <Route path="/services">
                    <Services></Services>
                  </Route>
                  <Route path="/contact">
                    <Contact></Contact>
                  </Route>
                  <Route path="/payment/:id">
                    <Payment></Payment>
                  </Route>
                  <Route path="/createpayment">
                    <CreatePayment></CreatePayment>
                  </Route>
                </Switch>
              </Router>
            </div>
          </Scrollbars>
      }
    </StripePaymentProvider>
  );
}

export default App;
