import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Footer from '../shared/Footer/Footer';
import Header from '../shared/Header/Header';
import "./Services.css"
// image for Services 
import img1 from '../../Images/services/1(R).png'
import img2 from '../../Images/services/2(R).png'
import img3 from '../../Images/services/1 (1)(R).png'
import img4 from '../../Images/services/2 (1)(R).png'
import img5 from '../../Images/services/3(R).png'
import img6 from '../../Images/services/4(R).png'
import img7 from '../../Images/services/performace-marketing.jpg'
import img8 from '../../Images/services/ui-ux.jpg'

const Services = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    return (
        <>
            {
                loading ?
                    <div className="loader">
                        <div data-aos="fade-in" data-aos-duration="1000" className="loader-title">
                            <h1>SERV<span className='text-dark'>ICES</span></h1>
                            <Spinner className='p-2 mx-2' animation="grow" size='sm' ></Spinner>
                            <Spinner className='p-2' animation="grow" size='sm' ></Spinner>
                        </div >
                    </div >
                    :
                    <div>
                        <Header></Header>
                        <Container className='pt-5'>
                            <div data-aos="fade-up" data-aos-duration="1300" className='pt-5 pb-2'>
                                <h1 className='about-intro text-start pt-3'>
                                    Going beyond<br />
                                    what’s possible
                                </h1>
                            </div>
                        </Container>
                        {/* <div className='my-5'>
                <div className='brand-vid'>
                    <video
                        loop
                        autoPlay
                        muted
                    >
                        <source src="https://cdn.cuberto.com/cb/video/services/1.mp4" type="video/mp4" />
                    </video>
                </div>
            </div> */}
                        <Container className='py-5'>
                            <div data-aos="fade-up" data-aos-duration="1500" className='py-5'>
                                <Row className='py-3'>
                                    <Col xs={12} md={4} className='text-start'>
                                        <h4 className='p-3'>What we do</h4>
                                    </Col>
                                    <Col xs={12} md={8} className='text-start'>
                                        <p className='about-des p-3'>
                                            A strategic, adroit judicious and tactical approach is indispensable for long-term goals, but what’s even more salient is keeping deadlines and promises. Our highly worthwhile work gets results as well as  we never hide behind paperwork.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <Container className='py-3'>
                            <div id='website'>
                                <h1 className='about-intro text-center'>
                                    Websites<br />
                                    and platforms
                                </h1>
                            </div>
                        </Container>
                        <Container>
                            <Row className='py-5 g-3'>
                                <Col xs={12} md={6} className='p-5'>
                                    <img className='img-fluid w-75' src={img1} alt="" />
                                </Col>
                                <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                                    <div className='text-start px-4'>
                                        <h4 className='p-3'>Websites and platforms</h4>
                                        <p className='about-des p-3'>Within our collabations, developers work alongside designers. This is pivotal in constituting a sprightly and responsive amenable website that would exhilrate the audience.</p>
                                        <button style={{ fontWeight: '500' }} className="btn btn-outline-dark rounded-pill px-5 py-3 fs-5 my-4">Read more</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='py-4'>
                            <div id="app">
                                <h1 className='about-intro text-center'>
                                    Mobile<br />
                                    applications
                                </h1>
                            </div>
                        </Container>
                        <Container>
                            <Row className='py-5 g-5'>
                                <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                                    <div className='text-start px-4'>
                                        <h4 className='p-3'>Mobile applications</h4>
                                        <p className='about-des p-3'>Techguardians doesn’t do cookie-cutter solutions. Every project includes crucial stages of target audience research and prototype testing. The result? A product that’s flawlessly suited to our users.</p>
                                        <button style={{ fontWeight: '500' }} className="btn btn-outline-dark rounded-pill px-5 py-3 fs-5 my-4">Read more</button>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className='p-5'>
                                    <img className='img-fluid w-75' src={img2} alt="" />
                                </Col>
                            </Row>
                        </Container>
                        <Container className='py-4'>
                            <div id='ui_ux'>
                                <h1 className='about-intro text-center'>
                                    Strategy<br />
                                    and UI/UX
                                </h1>
                            </div>
                        </Container>
                        <Container>
                            <Row className='py-5 g-5'>
                                <Col xs={12} md={6} className='p-5'>
                                    <img className='img-fluid w-75' src={img8} alt="" />
                                </Col>
                                <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                                    <div className='text-start px-4'>
                                        <h4 className='p-3'>Strategy and UI/UX</h4>
                                        <p className='about-des p-3'>We help your brand create best of User interface (UI) design or user interface engineering  for machines and software, such as best of mobile/web applications & highly effective websites, with the focus on maximizing usability and the user experience.</p>
                                        <button style={{ fontWeight: '500' }} className="btn btn-outline-dark rounded-pill px-5 py-3 fs-5 my-4">Read more</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='py-4'>
                            <div>
                                <h1 className='about-intro text-center'>
                                    Design<br />
                                    concept
                                </h1>
                            </div>
                        </Container>
                        <Container>
                            <Row className='py-5 g-5'>
                                <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                                    <div className='text-start px-4'>
                                        <h4 className='p-3'>Design concept</h4>
                                        <p className='about-des p-3'>So, you have a startup with a limited budget? We make sure to build  a design concept for a site or mobile application to allure your audience and make your vaulable product even more prodigious.</p>
                                        <button style={{ fontWeight: '500' }} className="btn btn-outline-dark rounded-pill px-5 py-3 fs-5 my-4">Read more</button>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className='p-5'>
                                    <img className='img-fluid w-75' src="https://cdn.cuberto.com/cb/img/services/4.jpg" alt="" />
                                </Col>
                            </Row>
                        </Container>
                        <Container className='py-4'>
                            <div>
                                <h1 className='about-intro text-center'>
                                    Performance<br />
                                    marketing
                                </h1>
                            </div>
                        </Container>
                        <Container>
                            <Row className='py-5 g-5'>
                                <Col xs={12} md={6} className='p-5'>
                                    <img className='img-fluid w-75' src={img7} alt="" />
                                </Col>
                                <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                                    <div className='text-start px-4'>
                                        <h4 className='p-3'>Performance marketing</h4>
                                        <p className='about-des p-3'>Its not enough that your product shouldn't just be recognizable, it should be highly usable. We'll help you accomplish your target audience, track record conversions, and increase or elevate downloads and user loyalty.</p>
                                        <button style={{ fontWeight: '500' }} className="btn btn-outline-dark rounded-pill px-5 py-3 fs-5 my-4">Read more</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='py-5'>
                            <div>
                                <h1 className='about-intro text-start'>
                                    Benefits of working<br />
                                    with us
                                </h1>
                            </div>
                        </Container>
                        <Container>
                            <Row className='g-3 pb-5 mb-5'>
                                <Col className='px-4' xs={12} md={5}>
                                    <div className='text-start'>
                                        <div className='py-5'>
                                            <img className='w-25 img-fluid' src={img3} alt="" />
                                        </div>
                                        <h3 style={{ fontWeight: '500' }} className='mb-4'>Time zone ain't no thing</h3>
                                        <p className='fs-5'>
                                            Wherever you are in the world, you'll always feel like we’re right around the corner just for you. With about 10 years of experience, our business processes are quite seamless and time differences won't  matter at all.
                                        </p>
                                    </div>
                                </Col>
                                <Col className='px-4' xs={12} md={5}>
                                    <div className='text-start'>
                                        <div className='py-5'>
                                            <img className='w-25 img-fluid' src={img4} alt="" />
                                        </div>
                                        <h3 style={{ fontWeight: '500' }} className='mb-4'>Full spectrum of services</h3>
                                        <p className='fs-5'>
                                            Any solution your business needs, we’re on it: organizing  logos, development, interface design prior to development, high technical support, and well versed marketing.
                                        </p>
                                    </div>
                                </Col>
                                <Col className='px-4' xs={12} md={5}>
                                    <div className='text-start'>
                                        <div className='py-5'>
                                            <img className='w-25 img-fluid' src={img5} alt="" />
                                        </div>
                                        <h3 style={{ fontWeight: '500' }} className='mb-4'>Impossible? <br />
                                            We’re on it</h3>
                                        <p className='fs-5'>
                                            Impossible clearly does not exist in our vocabulary. We create products exactly as they were at design stage, no simplifications and no shortcuts.
                                        </p>
                                    </div>
                                </Col>
                                <Col className='px-4' xs={12} md={5}>
                                    <div className='text-start'>
                                        <div className='py-5'>
                                            <img className='w-25 img-fluid' src={img6} alt="" />
                                        </div>
                                        <h3 style={{ fontWeight: '500' }} className='mb-4'>Flexible work <br />
                                            terms</h3>
                                        <p className='fs-5'>
                                            Just like we stick to a certain fixed budget, we stay within a set Time and Materials framework. Whatever terms we ensure and  accede to will always depend on your project needs.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Footer></Footer>
                    </div>

            }
        </>
    );
};

export default Services;